import React, { useState } from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'
import { colors } from '../../../theme'
import ZooEventState from '../index.state'
import ZooEventEditor from './ZooEventEditor'

const COLOR_FOR_STATUS = {
  'active': colors.green,
  'past': colors.red,
  'future': colors.gray
}

const GAMEID_TO_TITLE = {
  'word-hop': 'Word Hop',
  'word-hunt': 'Word Hunt',
  'word-climb': 'Word Climb',
  'jumble-words': 'Word Jumbled',
  'torch-type': 'Torch Type',
  'word-wings': 'Word Wings',
  'word-pairs': 'Word Pairs',
  'word-woods': 'Word Woods',
  'word-wrecker': 'Word Wrecker',
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 10px 1fr 1fr 100px;
  grid-gap: 10px;
  height: 50px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  > :first-child {
    background-color: ${props => COLOR_FOR_STATUS[props.status]}
  }
  > * {
    display: flex;
    height: 100%;
    align-items: center;
  }
`

const ZooEvent = (props) => {
  const [editing, setEditing] = useState(false)
  const [event, setEvent] = useState({ ...props })
  const { _id, from, to, gameId } = event
  const { updateEvent } = ZooEventState.useContainer()

  let status
  if (from < new Date() && to > new Date()) status = 'active'
  if (to < new Date()) status = 'past'
  if (from > new Date()) status = 'future'

  if (editing) return <ZooEventEditor
    _id={_id}
    from={from}
    to={to}
    gameId={gameId}
    onCancel={() => setEditing(false)}
    onSave={async (event) => {
      await updateEvent(event)
      setEvent(event)
      setEditing(false)
    }}
  />
  return <Wrapper status={status} onClick={() => setEditing(true)}>
    <div />
    <div>
      <FormattedDate value={from} />
    </div>
    <div>
      <FormattedDate value={to} />
    </div>
    { gameId && <div>
      { GAMEID_TO_TITLE[gameId] }
    </div> }
  </Wrapper>
}

ZooEvent.propTypes = {

}

ZooEvent.defaultProps = {

}

export default ZooEvent
