import React from "react"

import ZooEvents from "../components/ZooEvents"

const ZooEventsPage = () => {
  if (typeof window === "undefined") return null

  return <ZooEvents />
}

export default ZooEventsPage
