import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Calendar } from 'grommet'
import { colors } from '../../../theme'
import { FormattedDate } from 'react-intl'
import { Save, Close } from 'grommet-icons'
import NotificationState from '../../Notifications/index.state'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Actions = styled.div`
  margin-left: auto;
  margin-top: 6px;
  display: flex;
  > * {
    margin-right: 8px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const Button = styled.div`
  height: 40px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const CancelButton = styled(Button)`
  background-color: ${colors.gray};
`

const SaveButton = styled(Button)`
  background-color: ${colors.green};
`

const StyledCalendar = styled(Calendar)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: white;
  color: #000;
  padding: 15px;
  width: 100%;
  width: auto;
  transform: translateY(50px);
`

const COLOR_FOR_STATUS = {
  'active': colors.green,
  'past': colors.red,
  'future': colors.gray
}

const GAMEID_TO_TITLE = {
  'word-hop': 'Word Hop',
  'word-hunt': 'Word Hunt',
  'word-climb': 'Word Climb',
  'jumble-words': 'Word Jumbled',
  'torch-type': 'Torch Type',
  'word-wings': 'Word Wings',
  'word-pairs': 'Word Pairs',
  'word-woods': 'Word Woods',
  'word-wrecker': 'Word Wrecker',
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 10px 1fr 1fr 100px;
  grid-gap: 10px;
  height: 50px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  > :first-child {
    background-color: ${props => COLOR_FOR_STATUS[props.status]}
  }
  > * {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
  }
`

const Select = styled.select`
  border: none;
  padding: 5px;
  outline: none;
  background: transparent;
  color: inherit;
  margin: 0;
  border: 1px solid rgba(0,0,0,0.33);
  border-radius: 4px;
  width: 100px;
  outline: none;
  height: 30px;
  align-self: center;
  background-color: white;
  margin-right: 20px;
`

const ZooEventEditor = (props) => {
  const [event, setEvent] = useState({ gameId: 'word-hop', ...props })
  const { from = new Date().toISOString(), to = new Date().toISOString(), gameId } = event
  const [fromCalendarDisplayed, setFromCalendarDisplayed] = useState(false)
  const [toCalendarDisplayed, setToCalendarDisplayed] = useState(false)
  const { notifySuccess, notifyError } = NotificationState.useContainer()

  useEffect(() => {
    if (event) {
      setFromCalendarDisplayed(false)
      setToCalendarDisplayed(false)
    }
  }, [event])

  const cancel = () => {
    props.onCancel()
  }

  const save = async (event) => {
    try {
      await props.onSave(event)
      notifySuccess({ title: 'Saved', text: 'Event saved!' })
    } catch (err) {      
      notifyError({ title: 'Error', text: 'Failed save event: '+err.message })
    }
  }

  const status = useMemo(() => {
    let status
    if (new Date(from) < new Date() && new Date(to) > new Date()) status = 'active'
    if (new Date(to) < new Date()) status = 'past'
    if (new Date(from) > new Date()) status = 'future'
    return status
  }, [from, to])

  return <Container><Wrapper status={status}>
    <div />
    <div>
      { /* eslint-disable-next-line */ }
      <div onClick={() => setFromCalendarDisplayed(!fromCalendarDisplayed)}><FormattedDate value={from} /></div>
      { fromCalendarDisplayed && <StyledCalendar
        size="small"
        date={from}
        onSelect={date => setEvent({ ...event, from: date })}
      /> }
    </div>
    <div>
      { /* eslint-disable-next-line */ }
      <div onClick={() => setToCalendarDisplayed(!toCalendarDisplayed)}><FormattedDate value={to} /></div>
      { toCalendarDisplayed && <StyledCalendar
        size="small"
        date={to}
        onSelect={date => setEvent({ ...event, to: date })}
      /> }
    </div>
    <Select
      value={gameId}
      onChange={(e) => setEvent({ ...event, gameId: e.target.value })}
    >
        {
          [...Object.keys(GAMEID_TO_TITLE)].map(option => (
            <option key={option} value={option}>
              {GAMEID_TO_TITLE[option]}
            </option>
          ))
        }
    </Select>
  </Wrapper>
  <Actions>
    { props.onCancel && <CancelButton onClick={cancel}>
      <Close color='#fff' />
    </CancelButton> }
    <SaveButton onClick={() => save(event)}>
      <Save color='#fff' />
    </SaveButton>
  </Actions>

  </Container>
}

ZooEventEditor.propTypes = {

}

ZooEventEditor.defaultProps = {
  onSave: () => {}
}

export default ZooEventEditor
