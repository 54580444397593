import { createContainer } from "unstated-next"
import { useEffect, useState } from "react"
import { useApolloClient } from "@apollo/client"
import GET_EVENTS from './graphql/getEvents.graphql'
import CREATE_EVENT from './graphql/createEvent.graphql'
import UPDATE_EVENT from './graphql/updateEvent.graphql'

const useZooEventState = () => {
  const client = useApolloClient()
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetch()
    // eslint-disable-next-line
  }, [])

  const fetch = async () => {
    setLoading(true)
    const { data } = await client.query({
      query: GET_EVENTS,
      variables: {},
      fetchPolicy: 'network-only'
    })
    setEvents(([...(data.zooEvents || [])]).sort((a, b) => b.to - a.to))
    setLoading(false)
  }

  const createEvent = async ({ from, to, gameId }) => {
    let fromDate = new Date(from)
    let toDate = new Date(to)
    fromDate.setHours(14,0,0,0)
    toDate.setHours(14,0,0,0)

    const { data } = await client.mutate({
      mutation: CREATE_EVENT,
      variables: {
        input: { from: fromDate, to: toDate, gameId }
      }
    })
    setEvents([{ from: fromDate, to: toDate, gameId, ...data.createGameCompetitionEvent }, ...events])
  }

  const updateEvent = async ({ _id, from, to, gameId }) => {
    await client.mutate({
      mutation: UPDATE_EVENT,
      variables: {
        eventId: _id,
        input: { from, to, gameId }
      }
    })
    setEvents([...events].map(e => e._id === _id ? { ...e, from, to, gameId } : {...e})).sort((a, b) => b.to - a.to)
  }

  return { events, loading, createEvent, updateEvent }
}

const ZooEventState = createContainer(useZooEventState)

export default ZooEventState
