import React from 'react'
import ZooEventState from './index.state'
import ZooEvent from './component/ZooEvent'
import styled from 'styled-components'
import ZooEventEditor from './component/ZooEventEditor'
import { colors } from '../../theme'

const Events = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  width: 100%;
`

const Title = styled.div`
  color: ${colors.indigo};
  font-weight: bold;
  font-size: 18px;
  font-family: 'Roboto';
`

const ZooEvents = (props) => {
  const { loading, events, createEvent } = ZooEventState.useContainer()

  if (loading) return <div>Loading...</div>
  return <div>
    <Events>
      <Title>New Event</Title>
      <ZooEventEditor onSave={e => createEvent(e)}/>
      <Title>Events</Title>
      {
        events.map(e => <ZooEvent key={e._id} {...e} />)
      }
    </Events>
  </div>
}

ZooEvents.propTypes = {

}

ZooEvents.defaultProps = {

}

export default props => <ZooEventState.Provider>
  <ZooEvents {...props} />
</ZooEventState.Provider> 
